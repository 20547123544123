 
//
import { NGXLogger } from "ngx-logger";
import { featureType, authOpt, authType, windowMessageType, ILayer, authAs, isFeatureTypeForCluster, ISelectedFeatures, IFeatureInfo, IMenuFeatureItem, searchType, ISearchOnLayer, ISearchCondition, featureId, searchConditions, IOptiuneRes, featureTypeForVector } from "../definitions";
import { graphType } from "../features/features-components/info-click-graph.component";
import { ILayerFileInStore } from "../services/dbstore-leyerfiles";
import { MapController } from "./map-controller";
import { getExMessage, isArray, isUndefined } from "./map-utils";


import BaseLayer from 'ol/layer/Base';
import Overlay from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';
import Select from 'ol/interaction/Select';
import Feature from 'ol/Feature';
import Collection from "ol/Collection";
import Point from 'ol/geom/Point';
import Layer from 'ol/layer/Layer';
import ClusterSource from 'ol/source/Cluster';
import GeoJSONFormater from "ol/format/GeoJSON";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";
import * as olCoordinate from 'ol/coordinate';
import * as olProj from 'ol/proj';
import MapBrowserEvent from "ol/MapBrowserEvent";
import Popup from "ol-ext/overlay/Popup";
import { saveAs } from 'file-saver';

    export class MapCtrlSelectFeature {
        //
        public clickUrlQuery: string = '';
        public clickCoordinate: any = null;
        public clickLayer: any = null;
        public clickIsClassifResult = false;
        public reqStatus = {countReq: 0, countFin: 0};
        //
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };

        //
        //Details for clicked feature
        //
        public addInfoOverlay() {
            this.mapCtrl.infoOverlay = new Popup({
                id: "infoOverlayPopup",
                popupClass: "default", //"tooltips", "warning" "black" "default", "tips", "shadow",
                closeBox: false,
                // onshow: function () { },
                // onclose: function () { },
                positioning: 'auto',
                autoPan: false,
                //{   animation: { duration: 250 }},
                minibar: false
            })

            this.mapCtrl.map.addOverlay(this.mapCtrl.infoOverlay);
            this.mapCtrl.map.on('click', this.onMapClick);
        }

       
        public onMapClick = (event: MapBrowserEvent<any>) => {
            if (this.mapCtrl.selectionExtent) {
                this.mapCtrl.map.removeInteraction(this.mapCtrl.selectionExtent);
            }
            if (this.mapCtrl.greentopDwonloadButtonStateOn){
                return;
            } 
            if (this.mapCtrl.showAddFeature || this.mapCtrl.transportRouteShowEdit !== this.mapCtrl.routeShowType.hide) {
                //
            } else if (this.mapCtrl.tranzitButtonStateOn){
                let popup = this.mapCtrl.infoOverlay.getElement();
                this.buildTranzitInfoOverlay(event.coordinate, popup);
            } else {
                /*  pentru afisare info popup  */
                let popoverCntEvent = $(event.originalEvent.target).parents(".popover-content");
                if (popoverCntEvent.length === 0 && this.canUseFeatureInfo()) {
                    let hit = false;
                    let popup = this.mapCtrl.infoOverlay.getElement();
                    //greentop cassification result info
                    if (this.mapCtrl.greentopClassifyData.currentClasificare
                        && this.mapCtrl.greentopClassifyData.currentClasificare.antrenament
                        && this.mapCtrl.greentopClassifyData.resultTileLayer
                        && this.mapCtrl.greentopClassifyData.resultTileLayer.internalLayer
                        && this.mapCtrl.greentopClassifyData.resultTileLayer.internalLayer.getVisible() === true
                        ){
                            hit = true;
                            //
                            let tmpCoordRes = this.getLayerCoordResolutionReproj(event.coordinate, this.mapCtrl.greentopClassifyData.resultTileLayer);
                            let url: string = (this.mapCtrl.greentopClassifyData.resultTileLayer.internalLayer as any).getSource().getFeatureInfoUrl(
                                tmpCoordRes.coord, tmpCoordRes.resolution, tmpCoordRes.projection,
                                { 'INFO_FORMAT': 'text/html', 'FEATURE_COUNT': 50 });
                            this.$log.log("url raster info ", url);
                            //
                            this.clickUrlQuery = url.split('?')[1];
                            this.clickCoordinate = event.coordinate;
                            this.clickLayer = this.mapCtrl.greentopClassifyData.resultTileLayer;
                            this.clickIsClassifResult = true;
                            //
                            this.mapCtrl.gisDataService.loadRasterSelectInfoGreentopResult(this.mapCtrl.greentopClassifyData.currentClasificare.antrenament.id, this.clickUrlQuery || '')
                            .then((result) => {
                                if (result) {
                                    let info = this.buildRasterInfo(this.mapCtrl.greentopClassifyData.resultTileLayer, result);
                                    let firstVal = this.rasterInfoFirstVal(result);
                                    this.buildRasterInfoOverlay(event.coordinate, popup, info, this.mapCtrl.greentopClassifyData.resultTileLayer,firstVal);
                                } else {
                                    this.$log.log("no result for classif info raster ");
                                }
                            }).catch((reason) => {
                                this.$log.error("erroare info classif raster ", getExMessage(reason));
                             })
                        }

                    //raster info
                    this.mapCtrl.categories.forEach((catItem) => {
                        if (hit == false) {
                            catItem.layers.forEach((layItem) => {
                                if (hit == false) {
                                    if (layItem.featureType === featureType.tile
                                        && layItem.visible === true
                                    ) {
                                        //check layer has raster setting
                                        if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.raster_select_info, layItem.name, authType.layer)) {
                                            hit = true;
                                            //

                                            let tmpCoordRes = this.getLayerCoordResolutionReproj(event.coordinate, layItem);
                                            let url = (layItem.internalLayer as any).getSource().getFeatureInfoUrl(
                                                tmpCoordRes.coord, tmpCoordRes.resolution, tmpCoordRes.projection,
                                                { 'INFO_FORMAT': 'text/html', 'FEATURE_COUNT': 50 });
                                            this.$log.log("url raster info ", url);
                                            

                                            let urlquery = url.split('?')[1];
                                            //sld style url
                                            if (layItem.sldStyle && layItem.sldStyle.xmlResult && layItem.sldStyle.xmlResult.length > 0){
                                                const encodesld = encodeURIComponent(layItem.sldStyle.xmlResult);
                                                urlquery = urlquery + "&sld_body=" + encodesld;
                                            }
                                            this.clickUrlQuery = url.split('?')[1];
                                            this.clickCoordinate = event.coordinate;
                                            this.clickLayer = layItem;
                                            this.clickIsClassifResult = false;
                                            //
                                            this.mapCtrl.gisDataService.loadRasterSelectInfo(layItem.id, urlquery || '')
                                                .then((result) => {
                                                    if (result) {
                                                        let info = this.buildRasterInfo(layItem, result);
                                                        let firstVal = this.rasterInfoFirstVal(result);
                                                        this.buildRasterInfoOverlay(event.coordinate, popup, info, layItem, firstVal);
                                                    } else {
                                                        this.$log.log("no result for info raster ");
                                                    }
                                                    return true;
                                                }).catch((reason) => {
                                                    this.$log.error("erroare info raster ", getExMessage(reason));
                                                })
                                        }
                                    }
                                }
                            })
                        }
                    });
                    
                    this.mapCtrl.selectGrupFeatureOnLayer = {
                        layer: null,
                        features: new Collection<Feature>()
                    }

                    //vector info
                    this.mapCtrl.map.forEachFeatureAtPixel(event.pixel, (infeature: Feature, inlayer: Layer) => {
                       let featLayer = { feature: infeature, layer : inlayer};
                        if (hit == false) {
                            if (this.mapCtrl.infoOverlay != null) {
                                //
                                //cluster layer and feature
                                this.infoClusterSetFeatureAndLayer(featLayer);
                                //
                                if (featLayer.layer !== null && ("appLayer" in featLayer.layer)){
                                    //
                                    this.mapCtrl.windowMessageService.getFeatureExtraInfoByMessage(featLayer.feature, featLayer.layer['appLayer'] as ILayer, windowMessageType.featureExtraInfo)
                                        .then((data) => {
                                            if (data) {
                                                this.mapCtrl.windowMessageService.setFeatureExtraInfoFromMessage(featLayer.feature, featLayer.layer['appLayer'], data);
                                                return data;
                                            }
                                        })
                                        .then((data) => {
                                            return this.loadFeatureIRaportImages(featLayer.feature, featLayer.layer);
                                        })
                                        .then((data) => {
                                            return this.loadFeatureLayerImages(featLayer.feature, featLayer.layer);
                                        })
                                        .then((data) => {
                                            this.mapCtrl.windowMessageService.sendWindowMessage(JSON.stringify({
                                                type: "selectData", idMeasurement: (featLayer.feature.getId().toString()).split('.')[1], coordinate: event.coordinate
                                            }));
                                            return this.buildFeatureInfoOverlay(event.coordinate, popup, featLayer.layer, featLayer.feature);
                                        })
                                        .catch((messgage) => {
                                            this.$log.error("eroare informatii locatie")
                                        });
                                }
                            }
                           this.mapCtrl.selectGrupFeatureOnLayer.layer = featLayer.layer as any;
                        }
                        hit = true;
                        //add all features on pixel to list for grup features
                       if(this.mapCtrl.selectGrupFeatureOnLayer.layer && this.mapCtrl.selectGrupFeatureOnLayer.layer == featLayer.layer){
                            this.mapCtrl.selectGrupFeatureOnLayer.features.push(featLayer.feature);
                        }
                    });
                    //send message selected group with info
                    if (this.mapCtrl.selectGrupFeatureOnLayer.layer && ("appLayer" in this.mapCtrl.selectGrupFeatureOnLayer.layer)) {
                        if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.message_selected_byclick_features_info, this.mapCtrl.selectGrupFeatureOnLayer.layer['appLayer']['name'], authType.layer)) {
                            this.mapCtrl.windowMessageService.sendSelectedFeatureListInfoByMessage(this.mapCtrl.selectGrupFeatureOnLayer.features.getArray(),
                             this.mapCtrl.selectGrupFeatureOnLayer.layer[MapController.appLayer] as any, "msg-send-selected-byclick-info", authOpt.message_selected_byclick_features_info);
                        }
                    }
                    //
                    if (hit == false) {
                        (this.mapCtrl.infoOverlay as any).hide();
                        //if(this.mapCtrl.selectCluster && this.mapCtrl.selectCluster.getActive()){
                        //    this.mapCtrl.selectCluster.setActive(false);
                       // }
                    }
                }
            }

        };

        //deoarece getGetFeatureInfoUrl nu functioneaza bine la proiectii diferite,
        // convertim coordonata si resolutia de zoom in proiectia stratului
        private getLayerCoordResolutionReproj(coord: olCoordinate.Coordinate, layer: ILayer){
            const currentView = this.mapCtrl.map.getView();
            const currentProjection = currentView.getProjection();
            const newProjection = olProj.get(layer.projection);
            const currentResolution = currentView.getResolution();
            const newCoord = olProj.transform(coord, currentProjection, newProjection);
            const currentMPU = currentProjection.getMetersPerUnit();
            const newMPU = newProjection.getMetersPerUnit();
            const currentPointResolution =
              (olProj as any).getPointResolution(currentProjection, 1 / currentMPU, coord, 'm'
              ) * currentMPU;
            const newPointResolution =
            (olProj as any).getPointResolution(newProjection, 1 / newMPU, newCoord, 'm'
              ) * newMPU;
            const newResolution =
              (currentResolution * currentPointResolution) / newPointResolution;
            return{
              coord: newCoord,
              resolution: newResolution,
              projection: newProjection,
            };
        }

        private logTemporalRasterSelectInfo(layer: ILayer, urlquery: string, coordinate: any) {
            //permision tu use the option
            if (!this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.log_info_animate_raster_layer_all_timestamps, authType.object)
                && !this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.info_graph_timeline_mosaic, layer.name, authType.layer)
            ) {
                return;
            }
            //check if layer anymation is on
            if (this.mapCtrl.animateTimeRasterData.sourceRasterLayer
                && this.mapCtrl.animateTimeRasterData.sourceRasterLayer.id === layer.id
                && this.mapCtrl.animateTimeRasterData.selectSteps.length > 0
            ) {
                try {
                    let nullValue = null;
                    this.mapCtrl.animateTimeRasterData.graphType = graphType.line;
                    this.mapCtrl.animateTimeRasterData.stepValues.length = 0;
                    let nullValueOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.info_raster_null_value, layer.name, authType.layer);
                    if (nullValueOption && nullValueOption.idItem) {
                        nullValue = nullValueOption.idItem;
                    }
                    this.reqStatus.countFin = 0;
                    this.reqStatus.countReq = this.mapCtrl.animateTimeRasterData.selectSteps.length;
                    for (let index = 0; index < this.mapCtrl.animateTimeRasterData.selectSteps.length; index++) {
                        const timeStep = this.mapCtrl.animateTimeRasterData.selectSteps[index];
                        this.mapCtrl.animateTimeRasterData.stepValues[index] = null;
                        //replace time value
                        let timeFind = urlquery.split("&").filter((str) => str.toLowerCase().indexOf("time=") === 0);
                        if (timeFind == null || timeFind.length == 0) {
                            this.$log.log("time nu este in url params")
                            continue;
                        }
                        let resQuery = urlquery.replace(timeFind[0], "time=" + timeStep);
                        this.mapCtrl.gisDataService.loadRasterSelectInfo(layer.id, resQuery || '')
                            .then((result) => {
                                let rezVal = "; ";
                                Object.keys(result).forEach((key) => {
                                    rezVal += result[key] + "; "
                                })
                                for (const key in result) {
                                    if (Object.prototype.hasOwnProperty.call(result, key)) {
                                        if (result[key] !== nullValue) {
                                            this.mapCtrl.animateTimeRasterData.stepValues[index] = result[key];
                                        }
                                    }
                                    break;
                                }
                                //this.mapCtrl.animateTimeRasterData.stepValues[index] = result["GRAY_INDEX"];
                                if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.log_info_animate_raster_layer_all_timestamps, authType.object)) {
                                    this.$log.log(index + ' ' + layer.name + "; " + timeStep + "; " + coordinate[0] + "; " + coordinate[1] + rezVal); //+ " " + JSON.stringify(result)
                                }
                            }).catch((reason) => {
                                this.$log.error("Eroare extragere info raster temporal:", getExMessage(reason));
                            }).finally(()=>{
                                this.reqStatus.countFin += 1;
                            })
                    }

                } catch (error) {
                    this.$log.error("Eroare extragere informatii raster temporal")
                }
            }

        }

        private logTemporalRasterSelectInfoClassify(layer: ILayer, urlquery: string, coordinate: any) {
            //permision tu use the option info_graph_timeline_classify_result
            if (!this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.log_info_animate_raster_layer_all_timestamps, authType.object)
            && !this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.info_graph_timeline_classify_result, authType.object)
            ) {
                return;
            }
            //check if layer anymation is on
            if (this.mapCtrl.animateTimeRasterData.sourceRasterLayer
                && this.mapCtrl.animateTimeRasterData.sourceRasterLayer.id === layer.id
                && this.mapCtrl.animateTimeRasterData.selectSteps.length > 0
            ) {
                try {
                    let nullValue = null;
                    this.mapCtrl.animateTimeRasterData.graphType = graphType.bubble;
                    this.mapCtrl.animateTimeRasterData.stepValues.length = 0;
                    let nullValueOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.info_raster_null_value, layer.name, authType.layer);
                    if (nullValueOption && nullValueOption.idItem) {
                        nullValue = nullValueOption.idItem;
                    }
                    this.reqStatus.countFin = 0;
                    this.reqStatus.countReq = this.mapCtrl.animateTimeRasterData.selectSteps.length;
                    for (let index = 0; index < this.mapCtrl.animateTimeRasterData.selectSteps.length; index++) {
                        const timeStep = this.mapCtrl.animateTimeRasterData.selectSteps[index];
                        this.mapCtrl.animateTimeRasterData.stepValues[index] = null;
                        //replace time value
                        let timeFind = urlquery.split("&").filter((str) => str.toLowerCase().indexOf("time=") === 0);
                        if (timeFind == null || timeFind.length == 0) {
                            this.$log.log("time nu este in url params")
                            continue;
                        }
                        let resQuery = urlquery.replace(timeFind[0], "time=" + timeStep);
                        this.mapCtrl.gisDataService.loadRasterSelectInfoGreentopResult(this.mapCtrl.greentopClassifyData.currentClasificare.antrenament.id, resQuery || '')
                            .then((result) => {
                                let rezVal = "; ";
                                Object.keys(result).forEach((key) => {
                                    rezVal += result[key] + "; "
                                })
                                //take first value from object
                                for (const key in result) {
                                    if (Object.prototype.hasOwnProperty.call(result, key)) {
                                        if (result[key] !== nullValue) {
                                            this.mapCtrl.animateTimeRasterData.stepValues[index] = result[key];
                                        }
                                    }
                                    break;
                                }
                                //
                                if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.log_info_animate_raster_layer_all_timestamps, authType.object)) {
                                    this.$log.log(this.mapCtrl.greentopClassifyData.currentClasificare.denumire + "; " + timeStep + "; " + coordinate[0] + "; " + coordinate[1] + rezVal); //+ " " + JSON.stringify(result)
                                }

                            }).catch((reason) => {
                                this.$log.error("Eroare extragere info raster temporal:", getExMessage(reason));
                            }).finally(()=>{
                                this.reqStatus.countFin += 1;
                            })
                    }

                } catch (error) {
                    this.$log.error("Eroare extragere informatii raster temporal")
                }
            }

        }

        private infoClusterSetFeatureAndLayer(featLayer:{feature: Feature, layer: Layer}): void {
            //single feature in layer
            if (featLayer.layer && featLayer.layer["appLayer"]
                &&(isFeatureTypeForCluster(featLayer.layer["appLayer"].featureType))){
                let clusterfeatures = (featLayer.feature.get('features') as Array<Feature>)
                 if (clusterfeatures.length === 1){
                    featLayer.feature = clusterfeatures[0];
                    return;
                 }
             }
            //from select interaction
            if (featLayer.feature.get('selectclusterfeature') === true){
                //this.mapCtrl.selectCluster.getLayer()
                let clusterfeatures = (featLayer.feature.get('features') as Array<Feature>)
                if (clusterfeatures.length > 0){
                    let feat = clusterfeatures[0];
                   // let selectCluster = this.mapCtrl.selectCluster;
                   // let selclfeat = selectCluster.getFeatures() as Collection<Feature>;
                   // let myclFeature = null;
                    let found = false;
                    let findLayer:ILayer = null;
                    //
                    this.mapCtrl.categories.forEach((catItem) => {
                        if (found === false) {
                            catItem.layers.forEach((layItem) => {
                                if (found === false) {
                                    if (isFeatureTypeForCluster(layItem.featureType)) {
                                        let source = ((layItem.internalLayer as VectorLayer<any>).getSource() as ClusterSource<any>).getSource();
                                        let findFeature = source.getFeatureById(feat.getId());
                                        if (findFeature && (findFeature as any).ol_uid === (feat as any).ol_uid){
                                            found = true;
                                            findLayer = layItem;
                                        }
                                    }
                                }
                            })
                        }
                    })
                    if (findLayer && feat){
                        featLayer.feature = feat;
                        featLayer.layer = findLayer.internalLayer as any;
                    }
                }
            }
        }

        public canUseFeatureInfo(): boolean {
            return this.mapCtrl.selectButtonStateOn === false
                && this.mapCtrl.measure.distance.buttonStateOn === false
                && this.mapCtrl.measure.area.buttonStateOn === false
                && this.mapCtrl.showEditFeature === false
                && this.mapCtrl.routeShowEdit === this.mapCtrl.routeShowType.hide;
        }

        public buildFeatureInfoOverlay(coordinate, popup, layer, feature) {
            //
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.hide_layer_feature_info, (layer['appLayer'] as ILayer).name)) {
                (this.mapCtrl.infoOverlay as any).hide();
                this.$log.log('popup info locatie dezactivat pentru stratul cu locatia selectata');
                return;
            }
            //
            this.mapCtrl.infoOverlay.setPosition(coordinate);
            let info = this.buildFeatureInfo(feature, layer);

            if (info == undefined) { info = 'fara informatii' }
            (this.mapCtrl.infoOverlay as any).hide();
            //
            
            let editButtons = $("<div class='btn-group btn-group-justified'></div>");
            let edit = $('<div class="btn btn-light btn-block" id="infBtnEditFeature"> Modifică info </div>');
            let editGeometry = $('<div class="btn btn-light btn-block" id="infBtnEditFeatureGeom"> Modifică locație </div>');
            let insertIntoConnected = $('<div class="btn btn-light btn-block" id="infBtnInsertConFeature"> Rate </div>');
            let infoConnectedFeatures = $('<div class="btn btn-light btn-block" id="infBtnInfoConFeatures"> Detalii </div>');
            let infoGroupFeatures = $(`<div class="btn btn-light btn-block" id="infBtnInfoGrpFeatures"> Suprapuse (${(this.mapCtrl.selectGrupFeatureOnLayer == null ? 0 : this.mapCtrl.selectGrupFeatureOnLayer.features.getLength()) || 0})</div>`);
            let downladDocuments = $('<div class="btn btn-light btn-block" id="infBtnDownloadDocuments"> Descarcă documente </div>')
            //
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.edit_layer_feature_info, (layer['appLayer'] as ILayer).name)) {
                $(editButtons).append(edit);
            }
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.edit_layer_feature_location, (layer['appLayer'] as ILayer).name)) {
                $(editButtons).append(editGeometry);
            }

            //
            let buttons = $("<div class='btn-group btn-group-justified'></div>");
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.connected_layer_activate_insert, (layer['appLayer'] as ILayer).name)) {
                $(buttons).append(insertIntoConnected);
                $(buttons).append(infoConnectedFeatures);
            }
            let print = $("<div class='btn btn-light ' id='infBtnPringDetailsFeatures' > Raport </div>");
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.report_layer_feature, layer['appLayer']['name'], authType.layer)) {
                $(buttons).append(print);
            }
            let printSpatiuVerde = $("<div class='btn btn-light ' id='infBtnPrintDetailsSpatiuVerdeFeatures' > Raport S.V.</div>");
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.report_layer_feature_spatiuverde, layer['appLayer']['name'], authType.layer)) {
                $(buttons).append(printSpatiuVerde);
            }
            let searchNearBy = $("<div class='btn btn-light ' id='infBtnSearchNearby' > Zona </div>");
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.search_layer_feature_nearby, layer['appLayer']['name'], authType.layer)) {
                $(buttons).append(searchNearBy);
            }
            if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.menu_grup_features_info, authType.object)) {
                $(buttons).append(infoGroupFeatures);
            }
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.download_documents,layer['appLayer']['name'], authType.layer)
                &&  (this.hasDocumentPath(layer['appLayer'], feature) !== null)) {
                $(buttons.append(downladDocuments));
            }
            //
            let buttonsRoute = $("<div class='btn-group btn-group-justified'></div>");
            let navigateRoute = $("<div class='btn btn-light' id='infBtnNavigateRoute'> Animează rută </div>")
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.play_layer_route, layer['appLayer']['name'], authType.layer)) {
                $(buttonsRoute).append(navigateRoute);
            }
            //
            let title = $(`<div style="min-width:300px"><span> Informații </span><span style="display: none">${new Date().getTime() / 1000}</span></div>`);
            let closeBtn = $('<span id="infoPopupCloseBtn"  class="float-right" > X </span>');
            $(title).append(closeBtn);
            //
            let header = $('<h5 class="card-header"></h5>');
            header.append(title);
            let body = $('<div class="card-body p-2"> ' + info + '</div>');
            body.append(editButtons);
            body.append(buttons);
            body.append(buttonsRoute);
            let card = $('<div class="card info-popover"> </div>');
            card.append(header);
            card.append(body);
            let content = $('<div> </div>');
            content.append(card);
            //
            (this.mapCtrl.infoOverlay as any).show(coordinate, content.html());
            //
            $('#infBtnEditFeature').on("click", { feature, layer }, this.onClickEditFeature);
            $('#infBtnEditFeatureGeom').on('click', { feature, layer }, this.mapCtrl.mapCtrlEditFeature.onClickEditGeometry);
            $('#infBtnEditFeatureGeom').on('click', () => {
                 (this.mapCtrl.infoOverlay as any).hide();
                });
            $('#infoPopupCloseBtn').on("click", () => {
                (this.mapCtrl.infoOverlay as any).hide();
                this.clearSelectFeatureInteraction();
            });
            $('#infBtnNavigateRoute').on('click', { feature, layer, popup }, this.mapCtrl.mapCtrlAnimateRoute.onClickNavigateButton);
            $('#infBtnInsertConFeature').on('click', { feature, layer, coordinate }, this.onInsertIntoConnected);
            $('#infBtnInfoConFeatures').on('click', { feature, layer, coordinate }, this.onInfoConnectedFeatures);
            //creaza colectia de elemente 
            let selectedFeaturesOnLayers: Collection<ISelectedFeatures> = new Collection();
            let selectedFeatures: ISelectedFeatures = { layer: layer as any, features: new Collection([feature]) };
            selectedFeaturesOnLayers.push(selectedFeatures);
            //
            $("#infBtnPringDetailsFeatures").on('click', { selectedFeaturesOnLayers, coordinate }, this.mapCtrl.mapCtrlSelectBox.onClickPrintSelectedFeatures);
            $('#infBtnPrintDetailsSpatiuVerdeFeatures').on('click', { selectedFeaturesOnLayers, coordinate }, this.mapCtrl.mapCtrlSelectBox.onClickPrintSelectedFeatureSpatiuVerde);
            $('#infBtnSearchNearby').on("click", { feature, layer, coordinate }, this.onClickSearchNearByFeature);
            //
            $('#infBtnInfoGrpFeatures').on("click", {}, this.onInfoGroupFeatures);
            //
            $('#infBtnDownloadDocuments').on("click", {feature, layer}, this.onDownloadDocuments);
            //
            //$('.ireportimage').on("click",  this.onInfoFeatureImages);
            $('#btnIreportImage').on("click",  this.onInfoFeatureImagesI);
            $('#btnLayerImages').on("click",  this.onInfoFeatureImagesL);
            //bind start rating to element
            setTimeout(function () {
                $('.rating').removeClass('rating-loading').addClass('rating-loading').rating({
                    displayOnly: true,
                    showCaption: true,
                    starCaptions: function(val){ return val + ' stele';}
            });
            }, 100);
        }
        //
        public buildFeatureInfo(feature: Feature, layer: Layer): string {
            this.mapCtrl.popoverService.data.length = 0;
            var infoContent = "<div class='popupFeatureInfo'>";
            if (layer && MapController.appLayer in layer) {
                let props: Array<IFeatureInfo> = (<ILayer>layer[MapController.appLayer]).infoColumns;
                props.forEach((propItem: IFeatureInfo) => {
                    let propValue = feature.get(propItem.name);
                    if (propValue == null){
                        propValue = "";
                    }
                    //check if info should be shown
                    let configHide = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.in_info_feature, (<ILayer>layer[MapController.appLayer]).name, propItem.name, authType.layer);
                    if (isUndefined(configHide) || configHide == null || (configHide && configHide !== 'false')) {
                        //
                        //this.mapCtrl.PopoverService.data.push(`${propItem.name}: ${propValue}`);
                        let specialField = false;
                        let configStarItem = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.input_rate_item, (<ILayer>layer[MapController.appLayer]).name, propItem.name, authType.layer);
                        if (configStarItem) {
                            specialField = true;
                            infoContent += `<p><span>${propItem.name}:</span> <input type="text" class="rating rating-loading" value="${propValue}" 
                                            data-min="0" data-max="5" data-step="0.2" data-stars="5"
                                            data-displayOnly="true" data-size="xs" title=""> </p>`;
                        }
                        if (specialField === false) {
                            //ireport image
                            let configIreportImage = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.info_ireport_image, (<ILayer>layer[MapController.appLayer]).name, propItem.name, authType.layer);
                            if (configIreportImage) {
                                specialField = true;
                                infoContent += '<p>' + propItem.name + ': </p>';
                                let imgre = this.mapCtrl.iReportImageCache.filter((item) => item.imagename === propValue);
                                if (imgre && imgre.length > 0) {
                                    infoContent += ` 
                                    <p>
                                        <img  style="width: 100%" src="${imgre[0].imageurl}"/>
                                        <button id="btnIreportImage" class="ireportimage" class="btn btn-light"  imagename="${imgre[0].imagename}" imageurl="${imgre[0].imageurl}">Vizualizeaza imagine</button>
                                    </p>
                                    
                                    `
                                    // <p><a  href="${imgre[0].imageurl}" target="_blank">Deschide imagine in pagina noua</a></p>
                                }
                            }
                        }
                        if (specialField === false){
                            //layer images
                            let configLayerImage = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.info_layer_images, (<ILayer>layer[MapController.appLayer]).name, propItem.name, authType.layer);
                            if (configLayerImage) {
                                specialField = true;
                                let firstValidImage: ILayerFileInStore = null;
                                let vals = (propValue as string).split(',');
                                vals.forEach((itval) => {
                                    let ind = Number(itval);
                                    if (isNaN(ind) === false && ind > 0) {
                                        let rescache = this.mapCtrl.layerImagesCache.filter((item) => item.id === ind);
                                        if (rescache && rescache.length > 0 && firstValidImage == null) {
                                            firstValidImage = rescache[0];
                                        }
                                    }
                                })
                                //
                                infoContent += '<p>' + propItem.name + ': </p>';
                                if (firstValidImage && firstValidImage.localURL.length > 0) {
                                    infoContent += ` 
                                    <p>
                                        <img  style="width: 100%" src="${firstValidImage.localURL}"/>
                                        <button id="btnLayerImages" class="ireportimage" class="btn btn-light"  imagename="${firstValidImage.id}" imageurl="${firstValidImage.localURL}">Vizualizeaza imagini [${this.mapCtrl.layerImagesCache.length || ''}]</button>
                                    </p>
                                    
                                    `
                                }

                                //
                            }
                        }
                        if (specialField === false) {
                            //default 
                            infoContent += '<p>' + propItem.name + ": " + propValue + '</p>';
                        }
                    }
                });
            }
            return infoContent + "</div>";
        }
        
        public buildRasterInfoOverlay(coordinate, popup, info: string, layer: ILayer, firstVal: string) {
            if (info == undefined) { info = 'fara informatii' }
            (this.mapCtrl.infoOverlay as any).hide();
            //(this.mapCtrl.infoOverlay as any).setElement(null);
            //
            this.mapCtrl.infoOverlay.setPosition(coordinate);
            //
            let title = $(`<div style="min-width:300px"><span> Informații </span><span style="display: none">${new Date().getTime() / 1000}</span></div>`);
            let closeBtn = $('<span id="infoPopupCloseBtn" class="float-right" > X </span>');
            let infoGraphTimelineMosaic = $('<div class="btn btn-light btn-block" id="infoGraphTimelineMosaic"> Grafic </div>');
            let infoGraph3D = $('<div class="btn btn-light btn-block" id="infoGraph3D"> Grafic 3D</div>');
            let infoAgroPlan = $('<div class="btn btn-light btn-block" id="infoAgroPlan"> Lucrari agricole</div>');
            $(title).append(closeBtn);

            let buttons = $("<div class='btn-group btn-group-justified'></div>");
            if(this.mapCtrl.animateTimeRasterData.sourceRasterLayer
                && this.mapCtrl.animateTimeRasterData.sourceRasterLayer.id === layer.id 
                && this.mapCtrl.animateTimeRasterData.steps.length > 0
                && (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.info_graph_timeline_mosaic, layer.name)
                || ((this.mapCtrl.greentopClassifyData
                    && this.mapCtrl.greentopClassifyData.resultTileLayer
                    && this.mapCtrl.greentopClassifyData.resultTileLayer.id === layer.id
                    && this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.info_graph_timeline_classify_result, authType.object))
            ))) {
                $(buttons).append(infoGraphTimelineMosaic);
            }
            //info graph 3d
            if (this.mapCtrl.animateTimeRasterData.sourceRasterLayer
                && this.mapCtrl.animateTimeRasterData.sourceRasterLayer.id === layer.id
                // && this.mapCtrl.animateTimeRasterData.steps.length > 0
                && (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.info_graph_3D, layer.name)
                )) {
                $(buttons).append(infoGraph3D);
            }
            //info agro plan classify layer or layer option
            if ((layer.id === -10 && this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.info_agro_plan_classify, authType.object))
                || this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.info_agro_plan, layer.name)){
                $(buttons).append(infoAgroPlan);
            }
            //
            let content = $('<div> </div>');
            let card = $('<div class="card info-popover"> </div>');
            let header = $('<h5 class="card-header"></h5>');
            header.append(title);
            let body = $('<div class="card-body p-2"> ' + info + '</div>');
            body.append(buttons);
            card.append(header);
            card.append(body);
            content.append(card);
            //
            (this.mapCtrl.infoOverlay as any).show(coordinate, content.html());
            //
            $('#infoPopupCloseBtn').on("click", () => {
                (this.mapCtrl.infoOverlay as any).hide();
                this.clearSelectFeatureInteraction();
            });
            $('#infoGraphTimelineMosaic').on("click", { layer, coordinate }, this.onInfoGraphTimelineMosaic);
            $('#infoGraph3D').on("click", { layer, coordinate }, this.onInfoGraph3D);
            $('#infoAgroPlan').on("click", { firstVal }, this.onInfoAgroPlan);
        }

        public buildRasterInfo(layer: ILayer, info: any): string {
            let infoContent = '<p>' + layer.name + '</p>';
            for (let prop in info) {
                infoContent += '<p>' + prop + ": " + info[prop] || "" + '</p>';
            }
            return infoContent;
        }

        public rasterInfoFirstVal(info: any): any {
            let res = "";
            for (let prop in info) {
                if (info[prop]){
                    res = info[prop];
                }
                break;
            }
            return res;
        }

        public buildTranzitInfoOverlay(coordinate, popup) {
            let coord4326 = olProj.transform( coordinate, this.mapCtrl.map.getView().getProjection(),'EPSG:4326')
            this.mapCtrl.infoOverlay.setPosition(coordinate);
            (this.mapCtrl.infoOverlay as any).hide();
            let tranzitButtons = $("<div class='btn-group btn-group-justified'></div>");
            let locatieStart = $('<div class="btn btn-light btn-block" id="infTranzBtnStartLocation"> Locație plecare </div>');
            let locatieStop = $('<div class="btn btn-light btn-block" id="infTranzBtnStopLocation"> Locație sosire</div>');
            $(tranzitButtons).append(locatieStart);
            $(tranzitButtons).append(locatieStop);
            //let content = $(`<div> long: ${(coord4326[0] as number).toFixed(5)} - lat: ${coord4326[1].toFixed(5)}</div>`);
            //
            let title = $('<div style="min-width:200px"><span> Selecție coordonate </span></div>');
            let closeBtn = $('<span id="infoPopupCloseBtn" class="float-right" > X </span>');
            $(title).append(closeBtn);
            //
            let content = $('<div> </div>');
            let card = $('<div class="card info-popover-tranz"> </div>');
            let header = $('<h5 class="card-header"></h5>');
            header.append(title);
            let body = $(`<div class="card-body p-2"> <div class="h6"> long: ${(coord4326[0] as number).toFixed(5)} - lat: ${coord4326[1].toFixed(5)}</div></div>`);
            body.append(tranzitButtons);
            card.append(header);
            card.append(body);
            content.append(card);
            //
            (this.mapCtrl.infoOverlay as any).show(coordinate, content.html());
            //close on header click
            $('#infoPopupCloseBtn').on("click", () => {
                (this.mapCtrl.infoOverlay as any).hide();
            });
            $('#infTranzBtnStartLocation').on('click',(event)=>{
                if(this.mapCtrl.tranzitData.funcSetStartCoordinates){
                    this.mapCtrl.tranzitData.funcSetStartCoordinates(coord4326);
                }
                (this.mapCtrl.infoOverlay as any).hide();
            });
            $('#infTranzBtnStopLocation').on('click',(event)=>{
                if(this.mapCtrl.tranzitData.funcSetStopCoordinates){
                    this.mapCtrl.tranzitData.funcSetStopCoordinates(coord4326);
                }
                (this.mapCtrl.infoOverlay as any).hide();
            });
        }

      
        //
        //select interaction
        //
        public buildSelectFeatureInteraction() {
            //
            var defStyleSelectedFeature = new OlStyle({
                image: new CircleStyle({
                    stroke: new StrokeStyle({ color: "rgba(0,0,255,0.5)", width: 2 }),
                    fill: new FillStyle({ color: "rgba(0,0,255,0.3)" }),
                    radius: 5
                })
            })
            //
            let featureStyle = (feature: Feature): Array<OlStyle> => {
                let retStyle: Array<OlStyle> = [defStyleSelectedFeature];
                try {
                    let layer = this.mapCtrl.selectInteraction.getLayer(feature);
                    //layer can be provided by message selection
                    if (isUndefined(layer)) {
                        for (var i = 0; i < this.mapCtrl.selectedItemBuffer.length; i++) {
                            if (this.mapCtrl.selectedItemBuffer[i].feature.getId() === feature.getId()) {
                                layer = this.mapCtrl.selectedItemBuffer[i].layer;
                                break;
                            }
                        }
                    }
                    //end get layer
                    let stOnSelect = this.mapCtrl.mapOlInit.getStyleForFeature(layer["appLayer"], feature, true);
                    if (stOnSelect) {
                        retStyle = stOnSelect;
                    }
                } catch (reason) {
                    this.$log.error("eroare selectie stil", getExMessage(reason));
                }
                return retStyle;
            }
            this.mapCtrl.selectInteraction = new Select({ filter: this.filterSelect, style: featureStyle });
            this.mapCtrl.map.addInteraction(this.mapCtrl.selectInteraction);
            this.mapCtrl.selectedItemBuffer = [];
        }
        //
        public clearSelectFeatureInteraction() {
            if (this.mapCtrl.selectInteraction) {
                this.mapCtrl.selectInteraction.getFeatures().clear();
            }
        }
        //
        public canUseSelectTool(): boolean {
            return !this.mapCtrl.selectButtonStateOn
                && !this.mapCtrl.measure.distance.buttonStateOn
                && !this.mapCtrl.measure.area.buttonStateOn
                && !this.mapCtrl.routeAnimate
                && this.mapCtrl.routeShowEdit === this.mapCtrl.routeShowType.hide;

        }
        //
        public filterSelect = (feature: Feature, layer: BaseLayer) => {
            try {
                if (!this.canUseSelectTool()) {
                    return false;
                }
                let selLayer = layer["appLayer"] as ILayer;
                if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.select_layer_feature, selLayer.name, authType.layer)) {
                    return true;
                }
                return false;
            } catch (reason) {
                this.$log.error("eroare ");
                return false
            }
        }
        //
        public setSelectInteractionToFeature(feature: Feature, layer: VectorLayer<any>) {
            try {
                if (this.filterSelect(feature, layer)) {
                    //
                    if (this.mapCtrl.selectedItemBuffer.length > 10) {
                        this.mapCtrl.selectedItemBuffer.shift();
                    }
                    this.mapCtrl.selectedItemBuffer.push({ feature: feature, layer: layer });
                    //
                    let featCollection = this.mapCtrl.selectInteraction.getFeatures();
                    featCollection.clear();
                    featCollection.push(feature);
                }
            } catch (reason) {
                this.$log.error("eroare la interactiunea select", getExMessage(reason));
            }
        }

        //
        //search near by
        public onClickEditFeature = (object: JQueryEventObject) => {
            if (("data" in object)
                && ("feature" in object.data)
                && ("layer" in object.data)
            ) {
                let layer = object.data["layer"]["appLayer"] as ILayer;
                //todo
                //if (!object.data["layer"]["appLayer"]["auth"]) {
                //    this.$log.info("autorizare nu poate fi verificata")
                //    return;
                //} else {
                //    let auth = Number(object.data["layer"]["appLayer"]["auth"]);
                //    if (isNaN(auth) || auth < 2) {
                //        this.$log.info("neautorizat pentru editare")
                //        return;
                //    }
                //}

                
                let specificEditAction: IMenuFeatureItem;
                if (layer.menuFeatureItems && layer.menuFeatureItems.length > 0) {
                    let maction = layer.menuFeatureItems.filter((aitem) => { return aitem.action === "editFeature"; });
                    if (maction && maction.length > 0) {
                        specificEditAction = maction[0];
                    }
                }
                //specific or standard edit dialog
                if (specificEditAction) {
                    this.mapCtrl.featureMenuAction(layer, object.data["feature"], specificEditAction);
                } else {
                    this.mapCtrl.mapDialogs.showEditInfoDialog(object.data["feature"], object.data["layer"], true, null);
                }

                (this.mapCtrl.infoOverlay as any).hide();
            }
        };

        public onClickSearchNearByFeature = (object: BaseJQueryEventObject) => {
            if (("data" in object)
                && ("feature" in object.data)
                && ("layer" in object.data)
            ) {
                let feature = object.data["feature"] as Feature;
                let layer = object.data["layer"] as Layer;
                let geom = feature.clone().getGeometry().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326');
                // get feature geometry
                let geojsonFormat = new GeoJSONFormater();
                let polySelectionGeoJson = geojsonFormat.writeGeometryObject(geom);

                this.mapCtrl.searchSettings.feature = feature;
                // create buffer
                if (!this.mapCtrl.searchSettings.bufferDistance || this.mapCtrl.searchSettings.bufferDistance == "") {
                    this.mapCtrl.searchSettings.bufferDistance = "100";
                }

                this.mapCtrl.searchSettings.geometry = turf.buffer(polySelectionGeoJson as any, Number(this.mapCtrl.searchSettings.bufferDistance), { units: 'meters' });;

                // change search settings
                this.mapCtrl.searchSettings.type = searchType.layerfeature;
                this.mapCtrl.searchSettings.layer = layer[MapController.appLayer];

                // add search to layer
                let layerSearch: ISearchOnLayer = { layer: null, conditions: [] };
                layerSearch.layer = layer[MapController.appLayer];
                layerSearch.conditions = new Array<ISearchCondition>();
                layerSearch.conditions.push({
                    propertyName: featureId,
                    condition: searchConditions[0],
                    searchText: feature.getId().toString()
                });

                this.mapCtrl.searchSettings.layer.search = layerSearch;

                // refresh search
                (this.mapCtrl.infoOverlay as any).hide();
                this.mapCtrl.map.getLayers().forEach((litem) => {
                    if (litem instanceof VectorLayer && MapController.appLayer in litem) {
                        litem.changed();
                    }
                });
                // change search button status
                //this.searchActive = false;
                this.mapCtrl.searchActive = true;
                // this.map.render();
                

            }
        }
        //
        //insert into connected
        public onInsertIntoConnected = (object: JQueryEventObject): void => {
            //todo insert
            try {
                //extrage date
                let refFeature: Feature = object.data['feature'];
                let refLayer: Layer = object.data['layer'];
                let refCoordinate: olCoordinate.Coordinate = object.data['coordinate'];
                this.$log.log("insert into connected", JSON.stringify(refCoordinate));
                //verifica daca exista strat conex
                let insLayerNameOpt = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.connected_layer_name, refLayer['appLayer']['name'], authType.layer);
                if (insLayerNameOpt === undefined || insLayerNameOpt.descriere === undefined || insLayerNameOpt.descriere === '') {
                    throw new Error("lipseste nume strat connex");
                }
                let insLayer = this.mapCtrl.mapOlFeatures.searchForVectorLayer(insLayerNameOpt.descriere);
                if (insLayer === null) {
                    throw new Error("lipseste stratul connex");
                }
                //construieste locatie din coordonate
                this.mapCtrl.editFeatureReferenceLayer = insLayer;
                this.mapCtrl.newFeature = new Feature();
                this.mapCtrl.newFeature.setGeometry(new Point([refCoordinate[0], refCoordinate[1]]));
                //set properties from source feature to destination
                this.addDataToConnectedLayerFeature(this.mapCtrl.newFeature, refFeature, refLayer['appLayer'] as any, insLayer);
                //
                this.mapCtrl.mapCtrlEditFeature.addEditFeatureInfo();
            } catch (reason) {
                this.$log.error("eroare inserare locatie in strat conex: ", getExMessage(reason));
            }
        }

        private addDataToConnectedLayerFeature(feature: Feature, sourceFeature: Feature, sourceLayer: ILayer, targetLayer: ILayer) {
            try {
                // get connected fields
                let sourceOpt = this.mapCtrl.userSettingsSrvs.isAuthForItemOptionsAllInfo(authOpt.in_connected_layer_source, sourceLayer.name, authType.layer);
                let destOpt = this.mapCtrl.userSettingsSrvs.isAuthForItemOptionsAllInfo(authOpt.in_connected_layer_dest, sourceLayer.name, authType.layer);
                let srcDestOpt: Array<{ src: IOptiuneRes, dest: IOptiuneRes }> = [];
                sourceOpt.forEach((sitem) => {
                    let destItems = destOpt.filter((ditem) => ditem.idItem === sitem.idItem);
                    if (destItems && destItems.length > 0) {
                        srcDestOpt.push({ src: sitem, dest: destItems[0] });
                    }
                })
                // check fields in layers

                // copy information
                srcDestOpt.forEach((srcdestItem) => {
                    try {
                        feature.set(srcdestItem.dest.descriere, sourceFeature.get(srcdestItem.src.descriere));
                    } catch (reason) {
                        this.$log.error("eroare set informatii conexe", getExMessage(reason));
                    }
                })
            } catch (reason) {
                this.$log.error("eroare copiere informatii conexe", getExMessage(reason));
            }
        }

        public onInfoConnectedFeatures = (object: JQueryEventObject): void => {
            try {

                //extrage date
                let refFeature: Feature = object.data['feature'];
                let refLayer: ILayer = object.data['layer']['appLayer'] as ILayer;
                //
                let insLayerNameOpt = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.connected_layer_name, refLayer.name, authType.layer);
                if (insLayerNameOpt === undefined || insLayerNameOpt.descriere === undefined || insLayerNameOpt.descriere === '') {
                    throw new Error("lipseste nume strat connex");
                }
                // cautam stratul din harta
                let connLayer = this.mapCtrl.mapOlFeatures.searchForVectorLayer(insLayerNameOpt.descriere);
                if (connLayer === null) {
                    throw new Error("lipseste stratul connex");
                }
                // luam primul raport din lista //se va modifica
                let reportRef = refLayer.reports.filter((repItem) => repItem.dataLayer.name === connLayer.name);
                if (reportRef == null) {
                    throw new Error("eroare selectie raport connex");
                }
                //extragem din raport stratul si locatile conexe
                let connectedLayer = reportRef[0].dataLayer;
                let connectedFeatures = reportRef[0].dataFeatures.filter((fitem) => { return fitem.reportFeature == refFeature });
                //lansam ferestra de dialog
                this.mapCtrl.mapDialogs.showInfoConnectedFeaturesDialog(refFeature, connectedFeatures[0].dataFeatures || [], refLayer, connectedLayer);
                //
            } catch (reason) {
                this.$log.error("Eroare informatii conexe" , getExMessage(reason));
            }
        }

        public onInfoFeatureImagesI = (eventData: any): void =>{
            try {
                let imagesrc = eventData.currentTarget.getAttribute('imageurl');
                let imagename = eventData.currentTarget.getAttribute('imagename');
                if(imagesrc){
                    this.mapCtrl.mapDialogs.showInfoFeatureImagesDialog([{imagename:imagename,imageurl:imagesrc}]);
                }
                
            }catch (reason) {
                this.$log.error("Eroare imagine" , getExMessage(reason));
            }
        }
        public onInfoFeatureImagesL = (eventData: any): void =>{
            try {
                if (this.mapCtrl.layerImagesCache.length > 0){
                 let imData = this.mapCtrl.layerImagesCache.map((item)=> {return {imagename:item.id.toString(), imageurl: item.localURL}});
                    this.mapCtrl.mapDialogs.showInfoFeatureImagesDialog(imData);
                }
            }catch (reason) {
                this.$log.error("Eroare imagine" , getExMessage(reason));
            }
        }

        public onInfoGroupFeatures = (object: JQueryEventObject): void =>{
            try {
                if(this.mapCtrl.selectGrupFeatureOnLayer && this.mapCtrl.selectGrupFeatureOnLayer.features.getLength() > 0){
                    this.mapCtrl.mapDialogs.showGrupFeaturesInfoDialog(this.mapCtrl.selectGrupFeatureOnLayer);
                } else {
                    throw new Error("nu sunt locatii in grup");
                }
            } catch (reason) {
                this.$log.error("Eroare grup locatii" , getExMessage(reason));
            }
        }

        public loadFeatureIRaportImages(feature: Feature, layer: Layer): Promise<any>{
            //let imgarr: {imgname: string, imgurl: string}[] = [];
            let promisearr: Promise<any> [] = [];
            if (layer && MapController.appLayer in layer) {
                let props: Array<IFeatureInfo> = (<ILayer>layer[MapController.appLayer]).infoColumns;
                for (let index = 0; index < props.length; index++) {
                    const propItem = props[index];
                    let propValue = feature.get(propItem.name) as string || '';
                    if (propValue && propValue.length > 0) {
                        let configIreportImage = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.info_ireport_image, (<ILayer>layer[MapController.appLayer]).name, propItem.name, authType.layer);
                        if (configIreportImage) {
                            //check if exists in cache
                            let imgExist =  this.mapCtrl.iReportImageCache.filter ((item)=>item.imagename === propValue);
                            if(imgExist == null || imgExist.length === 0){
                                let pritem = this.mapCtrl.userSettingsSrvs.getIreportImage(propValue);
                            promisearr.push(pritem)
                            }
                        }
                    }
                }
                if(promisearr.length > 0){
                    return Promise.all(promisearr).then((res)=>{
                        if(res && res.length > 0){
                            for (let index = 0; index < res.length; index++) {
                                const element = res[index];
                                if(element){
                                    this.mapCtrl.iReportImageCache.push(element);
                                }
                            }
                        }
                    })
                }
            }
        }

        public loadFeatureLayerImages(feature: Feature, layer: Layer): Promise<any>{
            let promisearr: Promise<any> [] = [];
            this.mapCtrl.layerImagesCache.length = 0;
            if (layer && MapController.appLayer in layer) {
                let props: Array<IFeatureInfo> = (<ILayer>layer[MapController.appLayer]).infoColumns;
                for (let index = 0; index < props.length; index++) {
                    const propItem = props[index];
                    let propValue = feature.get(propItem.name) as string || '';
                    if (propValue && propValue.length > 0) {
                        let configLayerImage = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.info_layer_images, (<ILayer>layer[MapController.appLayer]).name, propItem.name, authType.layer);
                        if (configLayerImage) {
                            let vals = (propValue as string).split(',');
                            vals.forEach((itval) => {
                                let ind = Number(itval);
                                if (isNaN(ind) === false && ind > 0) {
                                    //pictureIds.push(ind);
                                    promisearr.push(this.mapCtrl.userSettingsSrvs.layerFiles.getLayerFileByStore(ind));
                                }
                            })
                        }
                    }
                }
                //
                if(promisearr.length > 0){
                    return Promise.all(promisearr).then((res)=>{
                        if(res && res.length > 0){
                            for (let index = 0; index < res.length; index++) {
                                const element = res[index];
                                if(element){
                                    this.mapCtrl.layerImagesCache.push(element);
                                }
                            }
                        }
                    }).catch((message)=>{
                        this.$log.error("eroare incarcare imagini");
                    })
                }
            }
        }

        public onDownloadDocuments = (object: JQueryEventObject): void => {
            try {
                //extrage date
                let refFeature: Feature = object.data['feature'];
                let refLayer: ILayer = object.data['layer']['appLayer'] as ILayer;
                //document cadastru pdf
                let docCDpath = this.hasDocumentPath(refLayer, refFeature);
                if (docCDpath == null) {
                    throw new Error("lipseste path document");
                }
                //
                this.mapCtrl.userSettingsSrvs.cadastru.getDocCarteFunciara(docCDpath)
                    .then((docBlob)=>{
                        if (docBlob) {
                            let docName = docCDpath.replace('cadastruDocuments/','');
                            saveAs(docBlob, docName);
                        }
                    }).catch((reason)=>{
                        this.$log.error("Eroare descarcare documente de la server " , getExMessage(reason));
                    })

            } catch (reason) {
                this.$log.error("Eroare descarcare documente" , getExMessage(reason));
            }
        }

        public hasDocumentPath(layer: ILayer, feature: Feature): string {
            try {
                let featurePropNameCD = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.download_carte_funciara_prop_name, layer.name, authType.layer);
                if (featurePropNameCD === undefined || featurePropNameCD.descriere === undefined || featurePropNameCD.descriere === '') {
                    throw new Error("lipseste nume proprietate cale fisier carte funciara");
                }
                //cautam valoare proprietate path document
                let docCDpath = feature.get(featurePropNameCD.descriere) as string || '';
                if (docCDpath == null || docCDpath == '') {
                    throw new Error("nu exita cale document carte funciara");
                }
                return docCDpath;
            } catch (reason) {
                this.$log.error("Eroare verificare optiune download " , getExMessage(reason));
                return null;
            }
        }

        public featureActionFromMessage(layerName: string, actionName:string, parameters:Array<any>){
            try {
                if (this.mapCtrl.hasActiveDialogs()){
                    throw new Error("sunt dialoguri active");
                }
                if (this.mapCtrl.hasActiveDrawInteractions()){
                    throw new Error("sunt active interactiuni harta");
                }
                if (this.mapCtrl.hasActiveLowerBandMenu()){
                    throw new Error("sunt active meniuri banda ");
                }
                //search layer
                let selectedLayer: ILayer = this.mapCtrl.mapOlFeatures.searchForVectorLayer(layerName);
                if (!selectedLayer) {
                    throw new Error("Nu exista strat cu numele" + layerName);
                }
                if (selectedLayer.visible === false) {
                    throw new Error("Stratul nu este activ");
                }
                if (!featureTypeForVector(selectedLayer.featureType)) {
                    throw new Error("Stratul nu este de tip geometrie");
                }
                if (parameters.length == 0) {
                    throw new Error("nu sunt specificate proprietati pt filtrare");
                }
                //search feature
                let feature = this.mapCtrl.mapOlFeatures.searchForFirstFeatureOnLayer(selectedLayer, parameters);
                if (feature == null) {
                    throw new Error("nu exista feature")
                }
                //
                let coords = (feature.getGeometry() as any).getCoordinates();
                if (isArray(coords[0])) {
                    coords = coords[0].map((item) => item);
                    if (isArray(coords[0])) {
                        coords = coords[0].map((item) => item);
                    }
                }
                //this.mapCtrl.mapCtrlSelectFeature.buildFeatureInfoOverlay(coords, this.mapCtrl.infoOverlay.getElement(), selectedLayer.internalLayer, feature);
                //this.mapCtrl.mapCtrlSelectFeature.setSelectInteractionToFeature(feature, selectedLayer.internalLayer as VectorLayer<any>);
                switch (actionName) {
                    case "editLocation":
                        if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.edit_layer_feature_location, selectedLayer.name)) {
                            this.mapCtrl.mapCtrlEditFeature.onClickEditGeometry({ data: { feature: feature, layer: selectedLayer } } as any);
                        }
                        break;
                    case "editInfo":
                        if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.edit_layer_feature_info, selectedLayer.name)) {
                            this.onClickEditFeature({ data: { feature: feature, layer: selectedLayer.internalLayer } } as any);
                        }
                        break;
                    default:
                        break;
                }
            } catch (reason) {
                this.$log.error("Eroare la mesaj pentru mesaj actiune locatie", getExMessage(reason));
            }
        }

        public onInfoGraphTimelineMosaic = (object: JQueryEventObject): void =>{
            //
            try{
                if (this.clickIsClassifResult === true){
                    this.logTemporalRasterSelectInfoClassify(this.mapCtrl.greentopClassifyData.resultTileLayer, this.clickUrlQuery || '', this.clickCoordinate);
                } else {
                    this.logTemporalRasterSelectInfo(this.clickLayer, this.clickUrlQuery || '', this.clickCoordinate);
                }
                
            } catch (reason){
                this.$log.error("eroare info raster temporal");
            }
            //
            //this.$log.log("on info graph");
            let refCoordinate: any = object.data['coordinate'];
            if (refCoordinate){
                refCoordinate = olProj.transform(refCoordinate as any, this.mapCtrl.mapConfig.projection, 'EPSG:4326');
            }
            //
            let hasInfoGraph = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.info_graph_utilizare_reference, authType.object);
            //
            this.mapCtrl.mapDialogs.showInfoClickGraphDialog(
                {name: this.mapCtrl.animateTimeRasterData.sourceRasterLayer.name,
                 data: {steps: this.mapCtrl.animateTimeRasterData.selectSteps, values: this.mapCtrl.animateTimeRasterData.stepValues},
                 type: this.mapCtrl.animateTimeRasterData.graphType, coordinate: refCoordinate}
                 ,  hasInfoGraph, this.reqStatus)
        }
        //
        public onInfoGraph3D = (object: JQueryEventObject): void =>{
            //
            //this.$log.log("on 3D graph");
            let width = 2000;
            let offset = 1;
            let offsetOsm = 1;
            let scale = 10;
            let nullValue = -9999;
            let layer = this.mapCtrl.animateTimeRasterData.sourceRasterLayer;

            let nullValueOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.info_raster_null_value, layer.name, authType.layer);
            if (nullValueOption && nullValueOption.idItem) {
                nullValue = nullValueOption.idItem;
            }
            let widthOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.info_graph3d_width, layer.name, authType.layer);
            if (widthOption && widthOption.idItem) {
                width = widthOption.idItem;
                if (width < 1000){
                    width = 1000;
                } else if( width > 20000) {
                    width = 20000;
                }
            }
            let offsetOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.info_graph3d_offset, layer.name, authType.layer);
            if (offsetOption && offsetOption.idItem) {
                offset = offsetOption.idItem;
                if (offset < -width){
                    offset = -width;
                } else if( offset > width){
                    offset = width;
                }
            }
            let offsetOsmOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.info_graph3d_osm_offset, layer.name, authType.layer);
            if (offsetOsmOption && offsetOsmOption.idItem) {
                offsetOsm = offsetOsmOption.idItem;
                if (offsetOsm < -width){
                    offsetOsm = -width;
                } else if( offsetOsm > width){
                    offsetOsm = width;
                }
            }
            let scaleOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.info_graph3d_scaling, layer.name, authType.layer);
            if (scaleOption && scaleOption.idItem) {
                scale = scaleOption.idItem;
                if (scale < 1){
                    scale = 1;
                } else if( scale > 1000){
                    scale = 1000
                }
            }
            this.$log.log(`width: ${width};  offset: ${offset}; scale: ${scale}`);

            let refCoordinate: any = object.data['coordinate'];
            if (refCoordinate) {
                refCoordinate = olProj.transform(refCoordinate as any, this.mapCtrl.mapConfig.projection, 'EPSG:3857');
            }
            let coordWidth = Math.trunc(width/2);
            let refBbox = [
                refCoordinate[0] - coordWidth,
                refCoordinate[1] - coordWidth,
                refCoordinate[0] + coordWidth,
                refCoordinate[1] + coordWidth,
            ]
            //let refBbox = this.mapCtrl.map.getView().calculateExtent(this.mapCtrl.map.getSize());
            // refBbox = olProj.transformExtent(refBbox, this.mapCtrl.mapConfig.projection, 'EPSG:4326');
            // let cqlQuery = this.mapCtrl.mapOlInit.buildCQLQueryString(this.mapCtrl.animateTimeRasterData.sourceRasterLayer, refBbox);
            let time = this.mapCtrl.animateTimeRasterData.steps[this.mapCtrl.animateTimeRasterData.index]|| null;
            //
            this.mapCtrl.mapDialogs.showInfoClick3DGraphDialog(
                {
                    layer: this.mapCtrl.animateTimeRasterData.sourceRasterLayer,
                    bbox: refBbox,
                    coordinate: refCoordinate,
                    time: time,
                    width: width,
                    offset: offset,
                    offsetOsm: offsetOsm,
                    scale: scale,
                    nullValue: nullValue,
                    data: null
                }
            )
        }
        //
        public onInfoAgroPlan = (object: JQueryEventObject): void =>{
            let tipTeren = "";
            if (("data" in object)
            && ("firstVal" in object.data)
                ) {
                    tipTeren = object.data.firstVal;
                }
            //
            //this.$log.log("on Info agro plan");
            try {
                window.open("/greentop/lucrari-agricole-raport/" + tipTeren, '_blank')
            } catch (reason) {
                this.$log.error("Eroare descarcare raport lucrari agricole: " , getExMessage(reason));
            }
        }
    }
